import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  connect() {
    var category = this.categoryTarget.value;
    var subcategory = this.data.get("subcategory");
    this.getCategories(category, subcategory);
  }

  static get targets() {
    return ['category', 'subcategory'];
  }
  
  getCategories(category, subcategory) {
    var _self = this;
    $.ajax({
      url: '/utils/get_categories',
        type: 'get',
        dataType:"json",
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          _self.setSubcategory(category, subcategory, data);
        },
        error: function(jqXHR, textStatus, errorThrown){
          _self.setSubcategory(category, subcategory, []);
        }
    })
  }

  changeCategory(event) {
    var category = this.categoryTarget.value;
    this.getCategories(category, null);
  }

  setSubcategory(category, subcategory, categories) {
    if (!this.hasSubcategoryTarget) {
      return
    }
    switch(category) {
      case 'scholarship':
        this.appendSelectValues(this.subcategoryTarget, categories['scholarships'], subcategory ? subcategory : "all");
        break;
      case 'endowment':
        this.appendSelectValues(this.subcategoryTarget,categories['endowments'], subcategory ? subcategory : "all");
        break;
      case 'program_support':
          this.appendSelectValues(this.subcategoryTarget, categories['program_supports'], subcategory ? subcategory : "all");
          break;
      case 'unrestricted':
            this.appendSelectValues(this.subcategoryTarget, categories['unrestricteds'], subcategory ? subcategory : "all");
            break;
      default:
        this.appendSelectValues(this.subcategoryTarget, [], subcategory ? subcategory : "all");
        break;
    }
  }

    appendSelectValues(select_element, options_list, selected_value) {
      select_element.textContent = "";
      
      var newElement = document.createElement("option");
      newElement.value = "all";
      newElement.text = "All";
      if (selected_value == newElement.value) {
        newElement.selected = true;
      }
      select_element.appendChild(newElement);

      if (this.categoryTarget.value != "all") {
        var newElement = document.createElement("option");
        newElement.value = -1;
        newElement.text = "General";
        if (selected_value == newElement.value) {
          newElement.selected = true;
        }
        select_element.appendChild(newElement);
      }

      if (options_list.length == 0) {
        return; 
      }

      for (var i = 0; i < options_list.length; i++) {
        var newElement = document.createElement("option");
        newElement.value = options_list[i]["id"];
        newElement.text = options_list[i]["name"];
        if (selected_value == newElement.value) {
          newElement.selected = true;
        }
        select_element.appendChild(newElement);
      }
  }

  readURL(e) {
    var input = document.getElementById(e.target.id);
    var output = document.getElementById(e.target.id.replace("_input", "_output"));

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
    }
 }
}