import { Controller } from "@hotwired/stimulus";
import PaystackPop from "@paystack/inline-js";
import getSymbolFromCurrency from "currency-symbol-map";

Date.prototype.addMonths = function (value) {
  var n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};
export default class extends Controller {
  static get targets() {
    return [
      "form",
      "currency",
      "amount",
      "initialCurrency",
      "initialAmount",
      "setAmount",
      "amountsContainer",
      "amountGroup",
      "frequency",
      "periodDay",
      "notifyMe",
      "email",
      "country",
      "state",
      "nextPaymentDate",
      "paymentRecurring",
      "numberOfPayments",
      "notifyDaysBefore",
      "paymentSingle",
      "firstStepPage",
      "allocation",
      "subcategory",
      "reference",
      "affiliationBlock",
      "affiliation",
      "paySumLabel",
      "paystackPublic",
      "subaccounts",
      "flutterwavePublic",
      "remitaPublic",
      "remitaBillerPublic",
      "categoryEndowment",
      "categoryScholarship",
      "categoryProgramSupport",
      "categoryUnrestricted",
      "categoryError",
      "paymentMethod",
      "firstName",
      "firstNameError",
      "lastName",
      "companyName",
      "lastNameError",
      "companyNameError",
      "phone",
      "phoneError",
      "phoneRequired",
      "gradYear",
      "comment",
      "client_id",
      "transactionStatus",
      "customAmount",
      "customCurrency",
      "selectWrapper",
      "publishName",
      "publishAmount",
      "publishNameType",
      "payerBankCode",
      "payerAccount",
      "paymentType",
      "loader",
      "moreOptions",
      "matriculationNumber"
    ];
  }

  static values = {
    currencySettings: Array,
    initialCurrency: String,
    initialAmount: Number,
  };

  connect() {
    this.currencySettingsValue = JSON.parse(this.data.get("currency-settings-value") || "[]");
    this.initialCurrencyValue = this.data.get("initial-currency-value");
    this.initialAmountValue = Number(this.data.get("initial-amount-value"));

    if (this.currencyTarget) {
      this.currencyTarget.value = this.initialCurrencyValue;
    } else {
      console.error("Currency target not found!");
    }

    if (this.amountsContainerTarget) {
      this.updateAmounts();
    } else {
      console.error("Amounts container target not found!");
    }

    this.setupEventListeners();

    if (!this.hasCurrencyTarget) {
      console.error("Currency target not found!");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const otherSelected = urlParams.get('other');

    if (otherSelected === 'true') {
      this.showOtherAmountInput();
    } else {

      this.initializeCurrency();
    }
    this.disableStateSelect();
    this.setupStateInteraction();
    this.firstNameTarget.addEventListener("input", () =>
      this.validateFirstName()
    );
    this.lastNameTarget.addEventListener("input", () =>
      this.validateLastName()
    );
    this.companyNameTarget.addEventListener("input", () =>
      this.validateCompanyName()
    );
    this.phoneTarget.addEventListener("input", () =>
      this.validatePhone()
    );

    if (!this.frequencyTarget.value) {
      this.frequencyTarget.value = "";
    }

    if (this.payerBankCodeTarget) {
      this.payerBankCodeTarget.addEventListener("input", () => {
        this.updateHiddenField(this.payerBankCodeTarget, "payerBankCode");
      });
    }
    
    if (this.payerAccountTarget) {
      this.payerAccountTarget.addEventListener("input", () => {
        this.updateHiddenField(this.payerAccountTarget, "payerAccount");
      });
    }

    this.paymentTypeTarget.value = "Onetime";
  }

  updatePaymentMethod(selectedCurrency) {
    let subaccounts = JSON.parse(this.subaccountsTarget.value).filter(
      (subaccount) =>
        subaccount.subaccount_type === "donation" &&
        subaccount.currency.toLowerCase() === selectedCurrency.toLowerCase()
    );
  
    if (subaccounts.length > 0) {
      let payment_method = subaccounts[0].payment_method;
      this.paymentMethodTarget.value = payment_method;
      this.updateViewBasedOnPaymentMethod(payment_method);
    } else {
      console.error("No subaccount found for currency: ", selectedCurrency);
    }
  }

  updateViewBasedOnPaymentMethod(paymentMethod) {
    const remitaSection = document.getElementById('remita-section');
    const nonPaystackSection = document.getElementById('non-paystack-section');
    const nonRemitaSection = document.getElementById('non-remita-section');
    const recurringPaymentOption = document.getElementById('recurring');
    
    if (remitaSection) remitaSection.style.display = 'none';
    if (nonRemitaSection) nonRemitaSection.style.display = 'none';
    if (nonPaystackSection) nonPaystackSection.style.display = 'none';
    
    if (paymentMethod === 'remita') {
      remitaSection.style.display = 'block';
      recurringPaymentOption.parentElement.style.display = 'none';
    } else {
      nonRemitaSection.style.display = 'block';
      recurringPaymentOption.parentElement.style.display = 'block';
    }
    if (paymentMethod === 'paystack') {
      nonPaystackSection.style.display = 'none';
    }
  }

  updateHiddenField(sourceElement, targetName) {
    const hiddenInput = this.targets.find(targetName);
    if (hiddenInput) {
      hiddenInput.value = sourceElement.value;
    }
  }

  updateAllHiddenFields() {
    if (this.payerBankCodeTarget && this.targets.find('payerBankCode')) {
      this.targets.find('payerBankCode').value = this.payerBankCodeTarget.value;
    }
    if (this.payerAccountTarget && this.targets.find('payerAccount')) {
      this.targets.find('payerAccount').value = this.payerAccountTarget.value;
    }
  }

  changeRecurentPayment(event) {
    const paymentType = event.target.value;
  
    if (paymentType === "Recurring") {
      this.paymentTypeTarget.value = "Recurring";
      this.frequencyTarget.value = this.frequencyTarget.value || "daily";
      this.showElement(this.payerBankCodeTarget);
      this.showElement(this.payerAccountTarget);
      console.log("Recurring payment selected. Frequency set to:", this.frequencyTarget.value);
      this.nextPaymentDateTarget.innerText =
        "Your first payment is today, and the next payment is on " +
        this.calculateNextPaymentDate(
          this.frequencyTarget.value,
          this.periodDayTarget.value
        );
        if (this.paymentMethodTarget.value === "remita") {
          this.phoneTarget.required = true;
        }
    } else {
      this.paymentTypeTarget.value = "Onetime";
      this.frequencyTarget.value = "";
      this.hideElement(this.payerBankCodeTarget);
      this.hideElement(this.payerAccountTarget);
      console.log("Single payment selected. Frequency cleared.");

      this.phoneTarget.required = false;
    }
  }

  showElement(element) {
    if (element) {
      element.classList.remove('hide');
      element.style.display = 'block';
    }
  }
  
  hideElement(element) {
    if (element) {
      element.classList.add('hide');
      element.style.display = 'none';
    }
  }

  setupEventListeners() {
    if (this.firstNameTarget) {
      this.firstNameTarget.addEventListener("input", () => this.validateFirstName());
    }
    if (this.lastNameTarget) {
      this.lastNameTarget.addEventListener("input", () => this.validateLastName());
    }
    if (this.companyNameTarget) {
      this.companyNameTarget.addEventListener("input", () => this.validateCompanyName());
    }
  }

  disableStateSelect() {
    let stateSelect = this.stateTarget;
    if (stateSelect) {
      stateSelect.disabled = true;
    }
  }

  setupStateInteraction() {
    let selectWrapper = this.selectWrapperTarget;
    let stateError = document.getElementById("stateError");
    let countrySelect = this.countryTarget;

    selectWrapper.addEventListener("click", (event) => {
      if (
        countrySelect.value === "" ||
        countrySelect.value === "-1" ||
        countrySelect.value === "None"
      ) {
        stateError.style.display = "block";
      } else {
        stateError.style.display = "none";
      }
    });
  }

  initializeCurrency() {
    if (this.currencySettingsValue.length > 0) {
      this.updateAmounts();
    } else {
      console.warn("No currency settings available to initialize amounts.");
    }

    this.formTarget.addEventListener("submit", (event) =>
      this.completePayment(event)
    );

    let selectedInputGroup = document.getElementById(
      "group_" + this.allocationTarget.value
    );
    if (selectedInputGroup) {
      if (
        !this.subcategoryTarget.value ||
        this.subcategoryTarget.value.length === 0
      ) {
        let subcategories =
          selectedInputGroup.querySelectorAll(".form__radio-input");
        if (subcategories && subcategories.length > 0) {
          this.subcategoryTarget.value = subcategories[0].id.split("_")[1];
        }
      }
      let selectedInput = document.getElementById(
        this.allocationTarget.value + "_" + this.subcategoryTarget.value
      );
      selectedInput.checked = true;
      if (selectedInput) {
        this.selectElement(selectedInput);
      }
    }
  }

  updateAmounts() {
    this.currencyTarget.value = this.customCurrencyTarget.value;
    const currency = this.currencyTarget.value;

    this.updatePaymentMethod(currency);

    const amounts = this.getAmountsForCurrency(currency);
    this.displayAmounts(currency, amounts, this.initialAmountValue);
  }

  getAmountsForCurrency(currency) {
    return this.currencySettingsValue
      .filter(setting => setting.currency_name.toLowerCase() === currency.toLowerCase())
      .map(setting => setting.amount);
  }


  displayAmounts(currency, amounts, initialAmount) {
    const symbol = getSymbolFromCurrency(currency);
    amounts.sort((a, b) => a - b);

    let buttonsHtml = amounts.map((amount) => {
      const isSelected = initialAmount && amount.toString() === initialAmount.toString();
      return `<button type="button" class="amount-button btn__outline ${isSelected ? "selected btn__outline--active" : ""}" 
                data-action="donation#selectAmount" data-amount="${amount}">
                ${symbol} ${amount.toLocaleString()}
              </button>`;
    }).join("");

    buttonsHtml += `<button type="button" class="amount-button btn__outline" 
                     data-action="click->donation#showOtherAmountInput">Other</button>`;

    this.amountsContainerTarget.innerHTML = buttonsHtml;

    if (initialAmount) {
      this.setAmount(initialAmount);
    }
  }

  inputOtherAmount(event) {
    const inputElement = event.target;
    let inputValue = inputElement.value;
    const errorMessageElement = document.getElementById("otherAmountError");

    const originalValue = inputValue;

    inputValue = inputValue.replace(/[^0-9]/g, "");

    if (inputValue !== originalValue) {
      inputElement.value = inputValue;
      errorMessageElement.style.display = "block";
    } else {
      errorMessageElement.style.display = "none";
    }

    this.setAmount(parseInt(inputValue) || 0);
    this.highlightSelectedButton(parseInt(inputValue) || 0);
  }

  selectAmount(event) {
    const button = event.target.closest(".amount-button");
    if (!button) return;

    const amount = button.dataset.amount;
    this.setAmount(amount);
    this.highlightSelectedButton(amount);
  }

  setAmount(amount) {
    this.amountTarget.value = amount;
    this.highlightSelectedButton(amount);
  }
  
  highlightSelectedButton(selectedAmount) {
    const buttons = this.amountsContainerTarget.querySelectorAll(".amount-button");
    buttons.forEach(button => {
      button.classList.remove("selected", "btn__outline--active");
      if (button.dataset.amount === selectedAmount.toString()) {
        button.classList.add("selected", "btn__outline--active");
      }
    });
  }

  showOtherAmountInput() {
    const amountGroup = this.amountGroupTarget;
    if (amountGroup) {
      amountGroup.classList.remove("hide");
      this.customAmountTarget.focus();
    } else {
      console.error("Amount group not found");
    }
  }

  inputOtherAmount(event) {
    const inputElement = event.target;
    let inputValue = inputElement.value;
    const errorMessageElement = document.getElementById("otherAmountError");

    const originalValue = inputValue;
    inputValue = inputValue.replace(/[^0-9]/g, "");

    if (inputValue !== originalValue) {
      inputElement.value = inputValue;
      errorMessageElement.style.display = "block";
    } else {
      errorMessageElement.style.display = "none";
    }

    this.setAmount(parseInt(inputValue) || 0);
    this.highlightSelectedButton(parseInt(inputValue) || 0);
  }

  hideOtherAmountInput() {
    const amountGroup = this.amountGroupTarget;
    if (amountGroup && !amountGroup.classList.contains("hide")) {
      amountGroup.classList.add("hide");
    }
  }

  // Popups

  showSelectionPopup(event) {
    event.preventDefault();

    var elems = document.querySelectorAll(".donate-popup");

    [].forEach.call(elems, function (el) {
      var groups = el.querySelectorAll(".form__group");
      [].forEach.call(groups, function (group_el) {
        var category = event.target.id.split("_").slice(1).join("_");
        if (group_el.id == "group_" + category) {
          group_el.classList.remove("hide");
        } else {
          group_el.classList.add("hide");
        }
      });
      el.style.display = "block";
    });
  }

  cancelSelectionPopup(event) {
    event.preventDefault();
    var elems = document.querySelectorAll(".donate-popup");

    [].forEach.call(elems, function (el) {
      el.style.display = "none";
    });
  }

  selectFromSelectionPopup(event) {
    let parentElem = event.target.parentNode.parentNode.parentNode;
    let selectedInput = parentElem.querySelectorAll(
      ".form__group:not(.hide) input:checked"
    );

    if (selectedInput && selectedInput.length > 0) {
      let selected_id = selectedInput[0].id;
      let splitted = selected_id.split("_");
      this.allocationTarget.value = splitted.slice(0, -1).join("_");
      this.subcategoryTarget.value = splitted.at(-1);

      this.selectElement(selectedInput[0]);
    }

    var elems = document.querySelectorAll(".donate-popup");

    [].forEach.call(elems, function (el) {
      el.style.display = "none";
    });
  }

  selectElement(selectedInput) {
    this.categoryErrorTarget.classList.add("hide");

    let cards = document.querySelectorAll(".category__card");

    var _self = this;

    [].forEach.call(cards, function (card) {
      card.classList.remove("category__card--error");
      if (card.id == "card_" + _self.allocationTarget.value) {
        card
          .querySelectorAll(".category__type--selected")[0]
          .classList.remove("hide");
        card
          .querySelectorAll(".category__subcategory")[0]
          .classList.remove("hide");
        card.querySelectorAll(".category__type")[0].classList.add("hide");
        card.querySelectorAll(".category__subcategory")[0].innerText =
          selectedInput.labels[0].innerText;

        card.querySelectorAll(".default_description")[0].classList.add("hide");
        card
          .querySelectorAll(".subcategory_description")[0]
          .classList.remove("hide");
        card.querySelectorAll(".subcategory_description")[0].innerText =
          selectedInput.parentNode.querySelectorAll(".form__info")[0].innerText;
      } else {
        card
          .querySelectorAll(".category__type--selected")[0]
          .classList.add("hide");
        card
          .querySelectorAll(".category__subcategory")[0]
          .classList.add("hide");
        card.querySelectorAll(".category__type")[0].classList.remove("hide");

        card
          .querySelectorAll(".subcategory_description")[0]
          .classList.add("hide");
        card
          .querySelectorAll(".default_description")[0]
          .classList.remove("hide");
      }
    });
  }

  changeCategory(event) {
    var popups = event.target.parentNode.querySelectorAll(".donate-popup");
    if (popups && popups.length > 0) {
      popups[0].style.display = "block";
    }
  }

  // Affiliation show block

  changeAffiliation(event) {
    if (event.target.value == "alumnus") {
      this.affiliationBlockTarget.classList.remove("hide");
    } else {
      this.affiliationBlockTarget.classList.add("hide");
    }
    const affiliationValue = event.target.value;
  
    this.affiliationTarget.value = affiliationValue;
    console.log("Affiliation selected: ", this.affiliationTarget.value);
  }


  changeNotifyMe(event) {
    // this.notifyDaysBeforeTarget.hidden = event.target.checked;
  }

  changePeriodDay(event) {
    this.nextPaymentDateTarget.innerText =
      "Your first payment is today, and the next payment is on " +
      this.calculateNextPaymentDate(
        this.frequencyTarget.value,
        event.target.value
      );
  }

  changeFrequency(event) {
    const selectedFrequency = event.target.value;
    this.frequencyTarget.value = selectedFrequency;
    const moreOptionsElement = document.getElementById("moreOptions");
  
    if (selectedFrequency === "daily") {
      moreOptionsElement.style.display = "none";
    } else {
      moreOptionsElement.style.display = "block";
    }
    this.nextPaymentDateTarget.innerText =
      "Your first payment is today, and the next payment is on " +
      this.calculateNextPaymentDate(
        selectedFrequency,
        this.periodDayTarget.value
      );
  }

  calculateNextPaymentDate(frequency) {
    let date = new Date();
    
    switch (frequency) {
      case "daily":
        date.setDate(date.getDate() + 1);
        break;
      case "monthly":
        date.setMonth(date.getMonth() + 1);
        break;
      case "quarterly":
        date.setMonth(date.getMonth() + 3);
        break;
      case "biannually":
        date.setMonth(date.getMonth() + 6);
        break;
      case "annually":
        date.setFullYear(date.getFullYear() + 1);
        break;
      default:
        console.error("Invalid frequency:", frequency);
        return "Invalid frequency";
    }
  
    
    return date.toLocaleDateString('en-US', {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  }

  getQuarter(date = new Date()) {
    return Math.floor(date.getMonth() / 3 + 1);
  }

  // Countries

  changeCountry(event) {
    let stateError = document.getElementById("stateError");
    if (
      event.target.selectedOptions[0] &&
      event.target.selectedOptions[0].value != "-1"
    ) {
      this.changeStateByCountry(event.target.selectedOptions[0].value, null);
      this.stateTarget.disabled = false;
      stateError.style.display = "none";
    } else {
      this.changeStateByCountry(null, null);
      this.stateTarget.disabled = true;
    }
  }

  changeStateByCountry(country, selectedState) {
    if (country) {
      this.fillStatesOfCountry(country, selectedState);
    } else {
      this.stateTarget.textContent = "";
    }
  }

  fillStatesOfCountry(country, selectedState) {
    var params_data = {
      data: {
        country: country,
      },
    };

    var _self = this;
    $.ajax({
      url: "/countries/get_states_by_country",
      type: "get",
      dataType: "json",
      data: params_data,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        _self.appendStates(data);
        _self.stateTarget.value = selectedState;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        _self.appendStates([]);
        _self.stateTarget.value = selectedState;
      },
    });
  }

  appendStates(states) {
    this.stateTarget.textContent = "";
    for (var i = 0; i < states.length; i++) {
      var newElement = document.createElement("option");
      newElement.value = states[i][1];
      newElement.text = states[i][0];
      this.stateTarget.appendChild(newElement);
    }
  }

  // Validation functions
  completePayment(event) {
    const payerBankCodeInput = document.getElementById('payer-bank-code');
    const payerAccountInput = document.getElementById('payer-account-number');

    if (payerBankCodeInput && payerBankCodeInput.value) {
      this.payerBankCodeTarget.value = payerBankCodeInput.value;
      console.log("Final Setting payerBankCode:", payerBankCodeInput.value);
    }

    if (payerAccountInput && payerAccountInput.value) {
      this.payerAccountTarget.value = payerAccountInput.value;
      console.log("Final Setting payerAccount:", payerAccountInput.value);
    }

    console.log("Form Values Before Submit:");
    console.log("payerBankCode:", this.payerBankCodeTarget.value);
    console.log("payerAccount:", this.payerAccountTarget.value);

    let isValid = this.validateForm(this.formTarget);

    const amount = this.amountTarget.value;
    if (!isValid) {
      event.preventDefault();
      return;
    }

    let subaccounts = JSON.parse(this.subaccountsTarget.value).filter(
      (subaccount) =>
        subaccount.subaccount_type == "donation" &&
        subaccount.currency == this.currencyTarget.value.toLowerCase()
    );
    let subaccount_name = subaccounts[0].name;
    let payment_method = subaccounts[0].payment_method;
    this.paymentMethodTarget.value = payment_method;
    this.get_subaccount_rate(event, subaccount_name, payment_method);
  }

  completePaymentWithSubaccount(
    event,
    subaccount_rate,
    subaccount_name,
    payment_method
  ) {
    const frequency = this.frequencyTarget.value;
    if (payment_method == "paystack") {
      this.handlePaystackPayment(event, subaccount_name, subaccount_rate);
    } else if (payment_method == "flutterwave") {
      this.handleFlutterwavePayment(event, subaccount_name, subaccount_rate);
    } else if (payment_method === "remita_biller") {
      this.handleRemitaBillerPayment(event, subaccount_name, subaccount_rate);
    } else {
      this.handleRemitaPayment(event, subaccount_name, subaccount_rate);
    }
  }

  handlePaystackPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    var _selfInner = this;
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: _selfInner.paystackPublicTarget.value, // Replace with your public key
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value * 100,
      subaccount: subaccount_name || "",
      bearer: "account",
      currency: _selfInner.currencyTarget.value,
      ref: "" + Math.floor(Math.random() * 1000000000 + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      // label: "Optional string that replaces customer email"
      onSuccess: (transaction) => {
        console.log("Paystack transaction successful!");
        console.log("Transaction details:", transaction);
        _selfInner.referenceTarget.value = transaction.reference;
        let message = "Payment complete! Reference: " + transaction.reference;
        console.log("Transaction reference before submitting form: ", transaction.reference);
        _selfInner.formTarget.submit();
      },
      onCancel: () => {
        // user closed popup
      },
    });
  }

  handleRemitaBillerPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    const _selfInner = this;
    
    let publishNameType = 'person_name';
    const personNameElement = document.getElementById("personName");
    const companyNameElement = document.getElementById("companyName");

    if (personNameElement && personNameElement.checked) {
      publishNameType = 'person_name';
    } else if (companyNameElement && companyNameElement.checked) {
      publishNameType = 'company_name';
    }

    const endowmentElement = document.getElementById('card_endowment');
    const scholarshipElement = document.getElementById('card_scholarship');
    const programElement = document.getElementById('card_program_support');
    const unrestrictedElement = document.getElementById('card_unrestricted');
    const affiliationElement = this.hasAffiliationTarget ? this.affiliationTarget : null;
    const gradYearElement = this.hasGradYearTarget ? this.gradYearTarget : null;
    const matriculationNumberElement = this.hasMatriculationNumberTarget ? this.matriculationNumberTarget : null;
    const commentElement = this.hasCommentTarget ? this.commentTarget : null;

    let allocation = 'unrestricted';

    if (endowmentElement && !endowmentElement.querySelector('.category__type--selected').classList.contains('hide')) {
      allocation = 'endowment';
    } else if (scholarshipElement && !scholarshipElement.querySelector('.category__type--selected').classList.contains('hide')) {
      allocation = 'scholarship';
    } else if (programElement && !programElement.querySelector('.category__type--selected').classList.contains('hide')) {
      allocation = 'program_support';
    } else if (unrestrictedElement && !unrestrictedElement.querySelector('.category__type--selected').classList.contains('hide')) {
      allocation = 'unrestricted';
    }

    const params = {
      client_id: _selfInner.client_idTarget.value,
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      payment_type: "donation",
      first_name: _selfInner.firstNameTarget.value,
      last_name: _selfInner.lastNameTarget.value,
      affiliation: affiliationElement ? affiliationElement.value : "",
      phone: _selfInner.phoneTarget.value,
      matriculation_number: matriculationNumberElement ? matriculationNumberElement.value : "",
      grad_year:  gradYearElement ? gradYearElement.value : "",
      comment:  commentElement ? commentElement.value : "",
      company: _selfInner.companyNameTarget.value,
      frequency: _selfInner.frequencyTarget.value,
      number_of_payments: _selfInner.numberOfPaymentsTarget.value,
      notify: _selfInner.notifyMeTarget.value,
      notify_days_before: _selfInner.notifyDaysBeforeTarget.value,
      publish_name: _selfInner.publishNameTarget.value,
      publish_name_type: publishNameType,
      publish_amount: _selfInner.publishAmountTarget.value,
      allocation: allocation,
      payerBankCode: _selfInner.payerBankCodeTarget.value,
      payerAccount: _selfInner.payerAccountTarget.value,
      payment_type: _selfInner.paymentTypeTarget.value,
      period_day: _selfInner.periodDayTarget.value,
      notify_days_before: _selfInner.notifyDaysBeforeTarget.value
    }; 

    if (params.payment_type === "Recurring") {
      params.frequency = _selfInner.frequencyTarget.value;
      params.number_of_payments = _selfInner.numberOfPaymentsTarget.value;
      params.notify = _selfInner.notifyMeTarget.checked ? 1 : 0;
      params.notify_days_before = _selfInner.notifyDaysBeforeTarget.value;
    
      $.ajax({
        url: "/create_mandate",
        type: "post",
        dataType: "json",
        data: params,
        success: function (createMandateResponse) {
          console.log("Create Mandate Response:", createMandateResponse);
      
          if (createMandateResponse.success) {
            const mandateId = createMandateResponse.mandate_id;
            const requestId = createMandateResponse.request_id;
            const clientId = params.client_id;
            const currency = params.currency;
            const payerBankCode = params.payerBankCode;
      
            $.ajax({
              url: "/activate_mandate",
              type: "post",
              dataType: "json",
              data: {
                mandate_id: mandateId,
                request_id: requestId,
                client_id: clientId,
                currency: currency,
                payer_bank_code: payerBankCode
              },
              success: function (activateResponse) {
                console.log("Activate Mandate Response:", activateResponse);
                if (activateResponse.success && activateResponse.param === "OTP") {
                  const otp = prompt(activateResponse.description);
                  if (otp) {
                    const cardNumber = prompt(activateResponse.description2);
                    if (cardNumber) {
                      $.ajax({
                        url: "/validate_mandate",
                        type: "post",
                        dataType: "json",
                        headers: {
                          'REQUEST_ID': activateResponse.request_id,
                        },
                        data: {
                          client_id: clientId,
                          currency: currency,
                          remita_trans_ref: activateResponse.remita_trans_ref,
                          otp: otp,
                          card_number: cardNumber
                        },
                        success: function (validateResponse) {
                          console.log("Validate Mandate Response:", validateResponse);
                          if (validateResponse.success) {
                            console.log("Mandate validated successfully:", validateResponse.message);
                            alert("Mandate has been successfully activated!");
                            window.location.href = "/home";
                          } else {
                            console.log("Validation failed:", validateResponse.message);
                            alert("Mandate activation failed: " + validateResponse.message);
                          }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                          console.log("Error while validating mandate:", errorThrown);
                        }
                      });
                    }
                  }
                } else if (activateResponse.print_url) {
                  console.log("Print Mandate URL:", activateResponse.print_url);
                  window.open(activateResponse.print_url, "_blank");
                } else {
                  console.log("Activation failed:", activateResponse.message);
                }
              },
              error: function (jqXHR, textStatus, errorThrown) {
                console.log("Error while activating mandate:", errorThrown);
              }
            });
          } else {
            console.log("Create mandate failed:", createMandateResponse.message);
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("Error while creating mandate:", errorThrown);
        }
      });
    } else {
      $.ajax({
        url: "/generate_invoice",
        type: "post",
        dataType: "json",
        data: params,
        async: false,
        cache: false,
        success: function (data, textStatus, jqXHR) {
          if (data.statuscode == "025") {
            _selfInner.processRemitaBillerInvoice(data["RRR"]);
          } else {
            console.log("Error while generating invoice!");
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("Error while generating invoice!");
        },
      });
    }
  }

  handleRemitaPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    const _selfInner = this;
    const _self = this;
    
    let publishNameType = 'person_name';
    const personNameElement = document.getElementById("personName");
    const companyNameElement = document.getElementById("companyName");

    if (personNameElement && personNameElement.checked) {
      publishNameType = 'person_name';
    } else if (companyNameElement && companyNameElement.checked) {
      publishNameType = 'company_name';
    }

    const endowmentElement = document.getElementById('card_endowment');
    const scholarshipElement = document.getElementById('card_scholarship');
    const programElement = document.getElementById('card_program_support');
    const unrestrictedElement = document.getElementById('card_unrestricted');
    const affiliationElement = this.hasAffiliationTarget ? this.affiliationTarget : null;
    const matriculationNumberElement = this.hasMatriculationNumberTarget ? this.matriculationNumberTarget : null;
    const gradYearElement = this.hasGradYearTarget ? this.gradYearTarget : null;
    const commentElement = this.hasCommentTarget ? this.commentTarget : null;

    let allocation = 'unrestricted';

    if (endowmentElement && !endowmentElement.querySelector('.category__type--selected').classList.contains('hide')) {
      allocation = 'endowment';
    } else if (scholarshipElement && !scholarshipElement.querySelector('.category__type--selected').classList.contains('hide')) {
      allocation = 'scholarship';
    } else if (programElement && !programElement.querySelector('.category__type--selected').classList.contains('hide')) {
      allocation = 'program_support';
    } else if (unrestrictedElement && !unrestrictedElement.querySelector('.category__type--selected').classList.contains('hide')) {
      allocation = 'unrestricted';
    }

    const params = {
      client_id: _selfInner.client_idTarget.value,
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      payment_type: "donation",
      first_name: _selfInner.firstNameTarget.value,
      last_name: _selfInner.lastNameTarget.value,
      affiliation: affiliationElement ? affiliationElement.value : "",
      phone: _selfInner.phoneTarget.value,
      matriculation_number: matriculationNumberElement ? matriculationNumberElement.value : "",
      grad_year:  gradYearElement ? gradYearElement.value : "",
      comment:  commentElement ? commentElement.value : "",
      company: _selfInner.companyNameTarget.value,
      frequency: _selfInner.frequencyTarget.value,
      number_of_payments: _selfInner.numberOfPaymentsTarget.value,
      notify: _selfInner.notifyMeTarget.value,
      notify_days_before: _selfInner.notifyDaysBeforeTarget.value,
      publish_name: _selfInner.publishNameTarget.value,
      publish_name_type: publishNameType,
      publish_amount: _selfInner.publishAmountTarget.value,
      allocation: allocation,
      payerBankCode: _selfInner.payerBankCodeTarget.value,
      payerAccount: _selfInner.payerAccountTarget.value,
      payment_type: _selfInner.paymentTypeTarget.value,
      period_day: _selfInner.periodDayTarget.value,
      notify_days_before: _selfInner.notifyDaysBeforeTarget.value
    }; 

    if (params.payment_type === "Recurring") {
      params.frequency = _selfInner.frequencyTarget.value;
      params.number_of_payments = _selfInner.numberOfPaymentsTarget.value;
      params.notify = _selfInner.notifyMeTarget.checked ? 1 : 0;
      params.notify_days_before = _selfInner.notifyDaysBeforeTarget.value;

      _self.loaderTarget.classList.remove('hide');
    
      $.ajax({
        url: "/create_mandate",
        type: "post",
        dataType: "json",
        data: params,
        success: function (createMandateResponse) {
          console.log("Create Mandate Response:", createMandateResponse);
      
          if (createMandateResponse.success) {
            const mandateId = createMandateResponse.mandate_id;
            const requestId = createMandateResponse.request_id;
            const clientId = params.client_id;
            const currency = params.currency;
            const payerBankCode = params.payerBankCode;
      
            $.ajax({
              url: "/activate_mandate",
              type: "post",
              dataType: "json",
              data: {
                mandate_id: mandateId,
                request_id: requestId,
                client_id: clientId,
                currency: currency,
                payer_bank_code: payerBankCode
              },
              success: function (activateResponse) {  
                _self.loaderTarget.classList.add('hide');
                console.log("Activate Mandate Response:", activateResponse);
                if (activateResponse.success && activateResponse.param === "OTP") {
                  const otp = prompt(activateResponse.description);
                  if (otp) {
                    const cardNumber = prompt(activateResponse.description2);
                    if (cardNumber) {
                      _self.loaderTarget.classList.remove('hide');
                      $.ajax({
                        url: "/validate_mandate",
                        type: "post",
                        dataType: "json",
                        headers: {
                          'REQUEST_ID': activateResponse.request_id,
                        },
                        data: {
                          client_id: clientId,
                          currency: currency,
                          remita_trans_ref: activateResponse.remita_trans_ref,
                          otp: otp,
                          card_number: cardNumber
                        },
                        success: function (validateResponse) {
                          console.log("Validate Mandate Response:", validateResponse);
                          if (validateResponse.success) {
                            _self.loaderTarget.classList.add('hide');
                            console.log("Mandate validated successfully:", validateResponse.message);
                            setTimeout(() => {
                              alert("Mandate has been successfully activated!");
                              window.location.href = "/donators?success=true";
                            }, 100);
                          } else {
                            console.log("Validation failed:", validateResponse.message);
                            alert("Mandate activation failed: " + validateResponse.message);
                            window.location.href = "/donators?pending=true";
                          }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                          _self.loaderTarget.classList.add('hide');
                          console.log("Error while validating mandate:", errorThrown);
                          window.location.href = "/donators?pending=true";
                        }
                      });
                    } else {
                      window.location.href = "/donators?pending=true";
                    }
                  } else {
                    window.location.href = "/donators?pending=true";
                  }
                } else if (activateResponse.print_url) {
                  console.log("Print Mandate URL:", activateResponse.print_url);
                  window.open(activateResponse.print_url, "_blank");
                  window.location.href = "/donators?pending=true";
                } else {
                  console.log("Activation failed:", activateResponse.message);
                  window.location.href = "/donators?pending=true";
                }
              },
              error: function (jqXHR, textStatus, errorThrown) {
                _self.loaderTarget.classList.add('hide');
                console.log("Error while activating mandate:", errorThrown);
                window.location.href = "/donators?pending=true";
              }
            });
          } else {
            console.log("Create mandate failed:", createMandateResponse.message);
            window.location.href = "/donators?pending=true";
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("Error while creating mandate:", errorThrown);
          window.location.href = "/donators?pending=true";
        }
      });
    } else {
      $.ajax({
        url: "/generate_invoice",
        type: "post",
        dataType: "json",
        data: params,
        async: false,
        cache: false,
        success: function (data, textStatus, jqXHR) {
          if (data.statuscode == "025") {
            _selfInner.processInvoice(data["RRR"]);
          } else {
            console.log("Error while generating invoice!");
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("Error while generating invoice!");
        },
      });
    }
  }

  handleFlutterwavePayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    var _selfInner = this;

    const modal = FlutterwaveCheckout({
      public_key: _selfInner.flutterwavePublicTarget.value,
      tx_ref: "" + Math.floor(Math.random() * 1000000000 + 1),
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      subaccounts: subaccount_name
        ? [
            {
              id: subaccount_name,
              transaction_charge_type: "flat_subaccount",
              transaction_charge:
                subaccount_rate * _selfInner.amountTarget.value,
            },
          ]
        : [],
      payment_options: "card, banktransfer, ussd",
      customer: {
        email: _selfInner.emailTarget.value,
        phone_number: _selfInner.phoneTarget.value,
        name:
          _selfInner.firstNameTarget.value + _selfInner.lastNameTarget.value,
      },
      callback: function (payment) {
        _selfInner.referenceTarget.value = payment["transaction_id"];
        let message =
          "Payment complete! Reference: " + payment["transaction_id"];
        _selfInner.formTarget.submit();
        modal.close();
      },
      onclose: function (incomplete) {
        if (incomplete === true) {
          // Record event in analytics
        }
      },
    });
  }

  get_subaccount_rate(event, subaccount_name, payment_method) {
    var _selfInner = this;
    var params = {
      subaccount_name: subaccount_name,
      payment_method: payment_method,
      client_id: _selfInner.client_idTarget.value,
      reference: _selfInner.referenceTarget.value
    };
    var _self = this;

    $.ajax({
      url: "/get_subaccount_rate",
      type: "get",
      dataType: "json",
      data: params,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        if (data.errors != null) {
          console.log("callback Error on subaccounts rate change");
        } else {
          _self.completePaymentWithSubaccount(
            event,
            data,
            subaccount_name,
            payment_method
          );
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("callback Error on subaccounts rate change");
      },
    });
  }

  processInvoice(rrr) {
    var _selfInvoice = this;
    let publicKey = this.remitaPublicTarget.value;

    _selfInvoice.paymentChecked = false;
    _selfInvoice.isProcessing = false;
    var remita = RmPaymentEngine.init({
        key: publicKey,
        processRrr: true,
        extendedData: {
            customFields: [
                {
                    name: "rrr",
                    value: rrr,
                },
            ],
        },
        onSuccess: (response) => {
            _selfInvoice.paymentChecked = true;
            _selfInvoice.isProcessing = true;
        
            _selfInvoice.updateTransactionStatus(rrr, 'processing')
                .then(() => {
                    _selfInvoice.checkPaymentStatus(rrr)
                        .then((statusResponse) => {
                            window.location.href = "/donators?success=true";
                        })
                        .catch((error) => {
                            console.log("Error checking payment status:", error);
                            window.location.href = "/donators?success=true";
                        });
                })
                .catch((error) => {
                    console.log("Error updating transaction status:", error);
                    window.location.href = "/donators?success=true";
                });
        },
        onError: (response) => {
            console.log("callback Error Response", response);
            window.location.href = "/donators?pending=true";
        },
        onClose: () => {
            if (!_selfInvoice.paymentChecked && !_selfInvoice.isProcessing) {
                _selfInvoice.checkPaymentStatus(rrr)
                    .then((statusResponse) => {
                        if (statusResponse && statusResponse.success) {
                            window.location.href = "/donators?success=true";
                        } else {
                            window.location.href = "/donators?pending=true";
                        }
                    })
                    .catch((error) => {
                        console.log("Error checking payment status (onClose):", error);
                        window.location.href = "/donators?pending=true";
                    });
            }
        }
    });

    remita.showPaymentWidget();
  }

  updateTransactionStatus(rrr, status) {
      return fetch(`/donations/update_status`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({
              rrr: rrr,
              status: status
          })
      })
      .then(response => response.json())
      .then(data => {
          if (data.success) {
              console.log("Transaction status updated successfully.");
          } else {
              console.error("Failed to update transaction status.");
              throw new Error(data.message);
          }
      });
  }

  processRemitaBillerInvoice(rrr) {
    var _selfInvoice = this;
    let publicKey;

    if (this.paymentMethodTarget.value === 'remita_biller') {
        publicKey = this.remitaBillerPublicTarget.value;
    } else {
        publicKey = this.remitaPublicTarget.value;
    }

    _selfInvoice.paymentChecked = false;
    _selfInvoice.isProcessing = false;

    var remita = RmPaymentEngine.init({
        key: publicKey,
        processRrr: true,
        extendedData: {
            customFields: [
                {
                    name: "rrr",
                    value: rrr,
                },
            ],
        },
        onSuccess: (response) => {
            _selfInvoice.isProcessing = true;

            _selfInvoice.checkPaymentStatus(response.paymentReference)
                .then((statusResponse) => {
                    if (statusResponse && statusResponse.success) {
                        _selfInvoice.referenceTarget.value = response.paymentReference;
                        _selfInvoice.formTarget.submit();
                        window.location.href = "/donators?success=true";
                    } else {
                        window.location.href = "/donators?pending=true";
                    }

                    _selfInvoice.paymentChecked = true;
                })
                .catch((error) => {
                    console.log("Error checking payment status:", error);
                    window.location.href = "/donators?pending=true";
                    _selfInvoice.paymentChecked = true;
                })
                .finally(() => {
                    _selfInvoice.isProcessing = false;
                });
        },
        onError: (response) => {
            console.log("callback Error Response", response);
            window.location.href = "/donators?pending=true";
        },
        onClose: () => {
            if (!_selfInvoice.paymentChecked && !_selfInvoice.isProcessing) {
                _selfInvoice.checkPaymentStatus(rrr)
                    .then((statusResponse) => {
                        if (statusResponse && statusResponse.success) {
                            window.location.href = "/donators?success=true";
                        } else {
                            window.location.href = "/donators?pending=true";
                        }
                    })
                    .catch((error) => {
                        console.log("Error checking payment status (onClose):", error);
                        window.location.href = "/donators?pending=true";
                    });
            }
        }
    });

    remita.showPaymentWidget();
  }

  checkPaymentStatus(rrr) {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: `/donations/check_remita_status`,
            type: "POST",
            data: { rrr: rrr },
            success: (response) => {
                if (response && response.success) {
                    resolve(response);
                } else {
                    reject(new Error(response.message || "Payment is still pending or failed"));
                }
            },
            error: (xhr, status, error) => {
                console.error("Error checking payment status:", error);
                reject(error);
            }
        });
    });
  }

  validateForm(target) {
    let isValid = true;

    let cards = target.querySelectorAll("category__card");

    let subaccounts = JSON.parse(this.subaccountsTarget.value).filter(
      (subaccount) =>
        subaccount.subaccount_type == "donation" &&
        subaccount.currency == this.currencyTarget.value.toLowerCase()
    );

    if (
      !this.allocationTarget.value ||
      !this.subcategoryTarget.value ||
      subaccounts.length === 0
    ) {
      if (subaccounts.length === 0) {
        this.categoryErrorTarget.innerText = "Subaccount is not selected";
      } else {
        this.categoryErrorTarget.innerText = "Select Giving Category";
      }
      this.categoryErrorTarget.classList.remove("hide");
      document.getElementById(this.categoryErrorTarget.id).scrollIntoView();
      cards.forEach((card) => {
        card.classList.add("category__card--error");
      });

      isValid = false;
      return isValid;
    } else {
      this.categoryErrorTarget.classList.add("hide");
      cards.forEach((card) => {
        card.classList.remove("category__card--error");
      });
    }

    const affiliationElement = document.getElementById("relationship");

    if (affiliationElement) {
      const affiliationLabel = affiliationElement.previousElementSibling;

      if (affiliationElement.value === "") {
        isValid = false;
        affiliationElement.classList.add("form__input--error");
        if (affiliationLabel) {
          affiliationLabel.classList.add("form__input-label-error");
        }
        const affiliationError = document.getElementById("affiliationError");
        if (affiliationError) {
          affiliationError.classList.remove("hide");
        }
        affiliationElement.focus();
      } else {
        affiliationElement.classList.remove("form__input--error");
        if (affiliationLabel) {
          affiliationLabel.classList.remove("form__input-label-error");
        }
        const affiliationError = document.getElementById("affiliationError");
        if (affiliationError) {
          affiliationError.classList.add("hide");
        }
      }
    }

    let requiredFieldSelectors = "textarea:required, input:required";
    let requiredFields = target.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();

        isValid = false;

        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove(
          "form__input-label-error"
        );
        field.nextElementSibling.classList.add("hide");
      }
    });

    if (!isValid) {
      return false;
    }

    let invalidFields = target.querySelectorAll("input:invalid");

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();

        isValid = false;

        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove(
          "form__input-label-error"
        );
        field.nextElementSibling.classList.add("hide");
      }
    });

    return isValid;
  }

  validateFirstName() {
    const pattern = /^(?!.*([^\w])\1)[a-zA-Z.\- ']+$/;
    const maxLength = 35;
    const input = this.firstNameTarget;
    const errorMessage = this.firstNameErrorTarget;

    input.addEventListener('input', (event) => {
      if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength + 1);
        errorMessage.style.display = "block";
        errorMessage.textContent = `First name cannot exceed ${maxLength} characters.`;
      } else {
        errorMessage.style.display = "none";
      }
    });

    if (pattern.test(input.value)) {
      errorMessage.style.display = "none";
    } else {
      errorMessage.style.display = "block";
      errorMessage.textContent =
        "Please enter a valid name. Only letters, spaces, hyphens, and periods are allowed.";
    }
  }

  validateLastName() {
    const pattern = /^(?!.*([^\w])\1)[a-zA-Z.\- ']+$/;
    const maxLength = 35;
    const input = this.lastNameTarget;
    const errorMessage = this.lastNameErrorTarget;

    input.addEventListener('input', (event) => {
      if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength + 1);
        errorMessage.style.display = "block";
        errorMessage.textContent = `Last name must be less than ${maxLength} characters.`;
      } else {
        errorMessage.style.display = "none";
      }
    });

    if (pattern.test(input.value)) {
      errorMessage.style.display = "none";
    } else {
      errorMessage.style.display = "block";
      errorMessage.textContent =
        "Please enter a valid last name. Only letters, spaces, hyphens, and periods are allowed.";
    }
  }

  validatePhone() {
    const pattern = /^[0-9\-\s]+$/;
    const maxLength = 15;
    const input = this.phoneTarget;
    const errorMessage = this.phoneErrorTarget;
    const phoneRequiredError = this.phoneRequiredTarget;

    const selectedPaymentMethod = this.paymentMethodTarget.value;
  
    input.addEventListener('input', (event) => {
      if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength);
        errorMessage.style.display = "block";
        errorMessage.textContent = `Phone number cannot exceed ${maxLength} digits.`;
      } else {
        errorMessage.style.display = "none";
      }
  
      if (pattern.test(input.value)) {
        errorMessage.style.display = "none";
      } else {
        errorMessage.style.display = "block";
        errorMessage.textContent = "Please enter a valid phone number. Only digits, spaces, and dashes are allowed.";
      }
    });

    if (selectedPaymentMethod === 'remita') {
      if (input.value.trim() === '') {
        phoneRequiredError.style.display = "block";
      } else {
        phoneRequiredError.style.display = "none";
      }
    } else {
      phoneRequiredError.style.display = "none";
    }
  
    if (pattern.test(input.value)) {
      errorMessage.style.display = "none";
    } else {
      errorMessage.style.display = "block";
      errorMessage.textContent = "Please enter a valid phone number. Only digits, spaces, and dashes are allowed.";
    }
  }

  toggleCompanyNameRequired(event) {
    const errorMessage = this.companyNameErrorTarget;
    const companyNameInput = this.companyNameTarget;
    const companyNameLabel = companyNameInput.previousElementSibling;
    const isCompanyNameSelected = event.target.id === "companyName";
  
    if (isCompanyNameSelected) {
      companyNameInput.required = true;
    } else {
      companyNameInput.required = false;
      errorMessage.style.display = "none";

      if (companyNameInput.classList.contains("form__input--error")) {
        companyNameInput.classList.remove("form__input--error");
      }
  
      if (companyNameLabel && companyNameLabel.classList.contains("form__input-label-error")) {
        companyNameLabel.classList.remove("form__input-label-error");
      }
    }
  }

  validateCompanyName() {
    const pattern = /^(?!.*([^\w])\1)[a-zA-Z0-9.\- ']+$/;
    const maxLength = 50;
    const input = this.companyNameTarget;
    const errorMessage = this.companyNameErrorTarget;
    const payButton = document.querySelector("#submit-button");

    input.addEventListener('input', () => {
      let value = input.value;

      if (value.length > maxLength) {
        input.value = value.slice(0, maxLength);
        errorMessage.style.display = "block";
        errorMessage.textContent = `Company name cannot exceed ${maxLength} characters.`;
        payButton.disabled = true;
        return;
      }

      if (value.trim() === "") {
        errorMessage.style.display = "none";
        payButton.disabled = false;
        return;
      }

      if (!pattern.test(value)) {
        errorMessage.style.display = "block";
        errorMessage.textContent = "Invalid company name format.";
        payButton.disabled = true;
      } else {
        errorMessage.style.display = "none";
        payButton.disabled = false;
      }
    });
  }

  showError(isShown, field, errorMsg = "") {
    var _elem = field;
    this.errorContainerTargets.forEach((errorContainer) => {
      const errorType = errorContainer.dataset.errorType;
      if (_elem.id == errorType) {
        errorContainer.innerText = isShown ? errorMsg || "" : "";
      }
    });
  }
}
