import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static get targets() {
    return ['category', 'subcategory', 'link', 'parent_url'];
  }
 
  connect() {
    this.generateLink(this.categoryTarget.value, this.subcategoryTarget.value);
    this.fillSubcategoriesByCategory(this.categoryTarget.value);
  }

  changeCategory(event) {
    this.fillSubcategoriesByCategory(this.categoryTarget.value);
  }

  changeSubcategory(event) {
    this.generateLink(this.categoryTarget.value, this.subcategoryTarget.value);
  }

  generateLink(category, subcategory) {
    let mappedCategory = category;

    this.linkTarget.innerText = this.parent_urlTarget.value;
    if (mappedCategory)  {
      this.linkTarget.innerText += "?category=" + mappedCategory;
      if (subcategory && subcategory != "-1") {
        this.linkTarget.innerText += "&subcategory=" + subcategory;
      }
    }
  }

  fillSubcategoriesByCategory(category) {
    var params_data = {data: {
      category: category
    }}; 

    var _self = this;
    $.ajax({
      url: '/get_subcategories_by_category',
        type: 'get',
        dataType:"json",
        data: params_data,
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          _self.appendSubcategories(data);
          _self.generateLink(_self.categoryTarget.value, _self.subcategoryTarget.value);
        },
        error: function(jqXHR, textStatus, errorThrown){
          _self.appendSubcategories([]);
          _self.generateLink(_self.categoryTarget.value, _self.subcategoryTarget.value);
        }
    })
  }

  appendSubcategories(subcategories) {
    this.subcategoryTarget.textContent = "";
    
    var newElement = document.createElement("option");
    newElement.value = "-1";
    newElement.text = "None";
    this.subcategoryTarget.value = "-1";

    this.subcategoryTarget.appendChild(newElement);
    for (var i = 0; i < subcategories.length; i++) {
      var newElement = document.createElement("option");
      newElement.value = subcategories[i]["id"];
      newElement.text = subcategories[i]["name"];
      this.subcategoryTarget.appendChild(newElement);
    }
  }
}
